.custom-font {
    font-family: 'Roboto', sans-serif;
    color: #575757;
}

.custom-font-title {
    font-family: 'Meow Script', sans-serif;
    color: white;
}

.round-image {
    width: 400px;
    height: 400px;
    object-fit: cover;
}

@media (max-width: 576px) {
    #text_id {
        height: 100px;
        overflow: hidden;
    }
}

.dropdown-item:active {
    background-color: transparent !important;
    color: inherit;
    outline: none;
}

.custom-progress-bar {
    opacity: 0.8;
    transition: opacity 1.5s ease-in-out;
}

@keyframes fadeInUp {
    0% {
        opacity: 0;
        transform: translateY(100px);
    }
    100% {
        opacity: 1;
        transform: translateY(0px);
    }
}


.round-image {
    animation: fadeInUp 2s cubic-bezier(0.25, 1, 0.5, 1);
}

.fade-in-up {
    animation: fadeInUp 2s cubic-bezier(0.25, 1, 0.5, 1);
}

